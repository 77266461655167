window.onload = () => {
	(function ($, w) {
    w._global = {
        configs: {
            phoneMask: "+7(999) 999-9999",
        }
    }
})(jQuery, window);
	(function ($, w) {
    $(function () {
        $('img[data-src]').lazy();
    })
})(jQuery, window);
	(function ($, w) {
    $(function () {
        $('.js-scrollbar-inner').scrollbar()
    })
})(jQuery);
	(function($, w) {
    $(function() {
        var $inputs = $('input[type="tel"]');
        $inputs.inputmask({
            mask: ['8 (999) 999-99-99', '+7 (999) 999-99-99'],
            placeholder: '_',
            showMaskOnHover: false,
            showMaskOnFocus: true,
            clearIncomplete: true,
        });
        $inputs.on('keyup', function(event) {
            var $this = $(this);
            var value = $(event.target).inputmask("unmaskedvalue");
            if (value.length === 1 && $this.val().slice(0, 2) !== '+7' && value === "7") {
                $this.val('+7');
            }
        })
    })
})(jQuery, window);
	(function ($, window) {
    $(function () {
        $(".custom-file-input").on("change", function() {
            var fileName = $(this).val().split("\\").pop();
            $(this).siblings(".custom-file-label").addClass("selected").html(fileName || 'Выберите файл');
        });
    })
})(jQuery, window);



	$(() => {
    let $container = $('#header');

    let Quotes = () => {
        return {
            request() {
                return new Promise(resolve => {
                    let $el = $container.find('#headerData');
                    if (!$el.length) {
                        resolve([]);
                        return
                    }
                    let data = JSON.parse($el.html());
                    resolve(data);
                })
            },
            setTemplate(data) {
                let $container = $('.js_rand-quotes');
                let template = '<div class="b-ttl_desc"><%=desc%></div> <div class="b-ttl_autor font-italic"><%=author%></div>';
                let compiled = _.template(template);
                $container.html(compiled(data));
            },
            update() {
                this.request().then(response => {
                    let randKey = Math.floor(Math.random() * response.length);
                    this.setTemplate(response[randKey]);
                })
            },
            init() {
                this.update();
            },
        }
    };

    Quotes().init();


    //On Scroll Functionality
    $(window).scroll(() => {
        var windowTop = $(window).scrollTop();
        var $menu = $('#menu-fixed');
        windowTop > 50 ? $menu.addClass('menu-fixed').parent().css('padding-top', '150px') : $menu.removeClass('menu-fixed').parent().css('padding-top', 0);
    });

    //Click Logo To Scroll To Top
    $('#logo').on('click', () => {
        $('html,body').animate({
            scrollTop: 0
        }, 500);
    });

    //Smooth Scrolling Using Navigation Menu
    $('a[href*="#"]').on('click', function (e) {
        $('html,body').animate({
            scrollTop: $($(this).attr('href')).offset().top - 100
        }, 500);
        e.preventDefault();
    });

    //Toggle Menu
    $('#menu-toggle').on('click', () => {
        $('#menu-toggle').toggleClass('closeMenu');
        $('ul').toggleClass('showMenu');

        $('li').on('click', () => {
            $('ul').removeClass('showMenu');
            $('#menu-toggle').removeClass('closeMenu');
        });
    });
});
	
	(function ($, w) {
    $(function () {
        let classFindElem = '.js-accordion-card';
        let activeClass = 'open';
        let $accordion = $("#accordion");

        $accordion.on('show.bs.collapse', function (e) {
            let $tg = $(e.target);
            $tg.parents(classFindElem).addClass(activeClass);
        });

        $accordion.on('hide.bs.collapse', function (e) {
            let $tg = $(e.target);
            $tg.parents(classFindElem).removeClass(activeClass);
        })
    })
})(jQuery, window);
	(function ($) {
    $(function () {
        let $btns = $('.js-grow-nav .btn');
        let collapsedClass = 'collapsed';

        $('#collapseTwo').collapse('show');

        $btns.on('click', function () {
            let $btn = $(this);
            $($btn.attr('data-target')).collapse('show');
            $btn.prevAll().addClass(collapsedClass).addClass('next-opened');
            $btn.nextAll().addClass(collapsedClass).removeClass('next-opened');
            $btn.removeClass(collapsedClass).removeClass('next-opened');
        });

        let $container = $('#accordion-grow');
        if (!$container.length) return;
        let data = JSON.parse($container.find('#growData').html());
        let $linksModal = $container.find('.js_show-modal');
        let modal = {
            $el: $container.find('#growModal'),
            $title: $container.find('#growModal .js_modal-title'),
            $content: $container.find('#growModal .js_modal-content'),
        };

        $linksModal.on('click', function (e) {
            e.preventDefault();
            let $this = $(this);
            let key = $this.attr('data-key');
            let currentData = data[key];
            modal.$title.html(currentData.title);
            modal.$content.html(currentData.description);
            if ($( document ).width() < 800) {
                modal.$el.modal('show');
            }
        });









    })
})(jQuery);
	(function ($, w) {
    $(function () {
        let $container = $('#ymapsContainer');
        if (!$container.length) return;
        let pointsData = JSON.parse($container.find('#ymapsData').html());
        let modal = {
            $el: $container.find('#ymapsModal'),
            $title: $container.find('#ymapsModal .js_modal-title'),
            $content: $container.find('#ymapsModal .js_modal-content'),
        };

        ymaps.ready(function () {
            let map = new ymaps.Map('ymaps', {
                center: [57.624848, 39.885856],
                zoom: 17,
            });
            let placemark;
            let coordinates = [];

            let collection = new ymaps.GeoObjectCollection(null, {
                // Запретим появление балуна.
                hasBalloon: false,
                iconColor: '#3b5998',
            });
            let $template = $("#ymap-modal-template");
            let template = $template.find('script').html();

            $.each(pointsData, function (key, value) {
                placemark = new ymaps.Placemark(value.coordinates, value, {
                    balloonContent: 'test',
                });
                placemark.events.add('click', e => {
                    let target = e.get('target');
                    let data = target.properties._data;
                    let compiled = _.template(template);

                    modal.$title.html("");
                    modal.$content.html(compiled(data));
                    if ($( document ).width() < 800) {
                        modal.$el.modal('show');
                    } else {
                        $template.html('');
                        $template.html(compiled(data));
                        if (!$modal.hasClass('open')) {
                            $modal.addClass('open');
                        }
                    }
                    map.panTo(target.geometry.getCoordinates(), {useMapMargin: true});
                });
                collection.add(placemark);
                coordinates.push(value.coordinates);
            });
            map.geoObjects.add(collection);

            map.setBounds(ymaps.util.bounds.fromPoints(coordinates));

        });

        let $modal = $('.ymaps-modal');
        let $modalClose = $modal.find('.js-ymaps-modal-close');

        $modalClose.on('click', function (e) {
            e.preventDefault();
            if ($modal.hasClass('open')) {
                $modal.removeClass('open');
            }
        })


    })
})(jQuery, window);
	(function ($, w) {
    $(function () {
        let $elPhone = $('.js-free-phone');
        let $form = $('.js-free-form form');
        let sending = false;
        $elPhone.mask(w._global.configs.phoneMask);
        $form.validate({
            errorClass: "is-invalid",
            focusInvalid: false,
            errorElement: "div",
            submitHandler: function (form) {
                let $form = $(form);
                if (!sending) {
                    sending = true;
                    new Noty({
                        text: "Отправка формы",
                        type: "warning",
                        timeout: 3000
                    }).show();
                    $.post('/submit/', $form.serializeArray(), function (e) {
                        sending = false;
                        $('.modal').modal('hide');
                        if (e.success !== undefined) {
                            new Noty({
                                text: e.success,
                                type: "success",
                                timeout: 3000
                            }).show();
                        } else if (e.error !== undefined) {
                            new Noty({
                                text: e.error,
                                type: "error",
                                timeout: 3000
                            }).show();
                        }
                    });
                }
            },
            errorPlacement: function ($error, $element) {
                let $form = $element.parents('form');
                let $errorContainer = $form.find('#'+$element.attr('aria-describedby'));
                if ($errorContainer.length) {
                    $errorContainer.html($error.text());
                } else {
                    $element.append($error);
                }
            }
        })
    })
})(jQuery, window);
	(function ($, w) {
    $(function () {
        let $form = $('.js_modal-callback-form');
        let sending = false;
        $form.validate({
            errorClass: "is-invalid",
            focusInvalid: false,
            errorElement: "div",
            rules: {
                "file": {
                    required: true,
                    extension: "jpg|jpeg|png|gif"
                }
            },
            messages: {
                "file": {accept: 'Неправильный формат изображений'}
            },
            submitHandler: function (form) {
                let $form = $(form);
                if (!sending) {
                    sending = true;
                    var formData = [];
                    $('.modal').modal('hide');
                    formData = new FormData($form[0]);
                    new Noty({
                        text: "Отправка формы",
                        type: "warning",
                        timeout: 3000
                    }).show();
                    $form.trigger('reset');
                    $form.find('.custom-file-input').trigger('change');
                    $.ajax({
                        url: '/send/index.php',
                        type: 'POST',
                        data: formData,
                        processData: false,
                        contentType: false,
                        dataType: 'json',
                        success: function (e) {
                            sending = false;
                            if (e.success !== undefined) {
                                new Noty({
                                    text: 'Заявка успешно отправлена',
                                    type: "success",
                                    timeout: 3000,
                                }).show();
                            } else if (e.error !== undefined) {
                                new Noty({
                                    text: 'Ошибка отправки',
                                    timeout: 3000,
                                    type: "error",
                                }).show();
                            }
                        },
                    });
                }
            },
            errorPlacement: function ($error, $element) {
                let $form = $element.parents('form');
                let $errorContainer = $form.find('#' + $element.attr('aria-describedby'));
                if ($errorContainer.length) {
                    $errorContainer.html($error.text());
                } else {
                    $element.append($error);
                }
            }
        })
    })
})(jQuery, window);
	(function ($, w) {
    $(function () {
        let $sliderElement = $('.js-trend-slider');
        let $container = $('.js_trend');
        let $elemShowModal = $container.find('[data-show-modal]');
        let $modal = $container.find('#trend');

        let modals = {
            'education_trends': "/static/data/trend/education_trends.html",
            'new_pedagogical_technologies_in_education': "/static/data/trend/new_pedagogical_technologies_in_education.html",
            'new_information_technologies_in_education': "/static/data/trend/new_information_technologies_in_education.html",
            'startups_in_education': "/static/data/trend/startups_in_education.html",
            'new_professions': "/static/data/trend/new_professions.html",
        };


        $elemShowModal.on('click', function (e) {
            e.preventDefault();
            let $this = $(this);
            let modalName = $this.attr('data-show-modal');
            let url = modals[modalName];
            $('.modal.in').modal('hide');
            setTimeout(function () {
                $.ajax({
                    url:url,
                    success: response => {
                        $modal.find('.js_modal-content').html(response);
                        $modal.modal('show');
                    }
                })
            }, 300);
        });










        $sliderElement.owlCarousel({
            stagePadding: 28,
            lazyLoad: true,
            margin: 30,
            items: 4,
            loop: false,
            dots: false,
            autoHeight: true,
            responsive: {
                0 : {
                    items: 1,
                    loop: false,
                    dots: true,
                    autoHeight: true,
                },
                814: {
                    items: 2,
                    loop: false,
                    dots: true,
                    autoHeight: true,
                },
                1230: {
                    items: 3,
                    loop: false,
                    dots: true,
                    autoHeight: true,
                },
                1500: {
                    items: 4,
                    loop: false,
                    dots: true,
                    autoHeight: true,
                }
            }
        });
    });
})(jQuery, window);
	(function ($, w) {
    $(function () {
        let $container = $('#case');
        if (!$container.length) return;
        let data = JSON.parse($container.find('#caseData').html());
        let $linksModal = $container.find('.js_show-modal');
        let modal = {
            $el: $container.find('#caseModal'),
            $title: $container.find('#caseModal .js_modal-title'),
            $content: $container.find('#caseModal .js_modal-content'),
        };

        $linksModal.on('click', function (e) {
            e.preventDefault();
            let $this = $(this);
            let key = $this.attr('data-key');
            let currentData = data[key];
            modal.$title.html(currentData.modal.title);
            modal.$content.html(currentData.modal.content + '<div id="vk_comments"></div><script type="text/javascript">VK.Widgets.Comments("vk_comments",{limit: 5, attach: false},' + key + ');</script>');
            modal.$el.modal('show');
        });


        //----- SLIDER ------


        let $elementSlider = $('.js-case-slider');
        $elementSlider.owlCarousel({
            stagePadding: 28,
            lazyLoad: true,
            margin: 30,
            items: 4,
            loop: false,
            dots: false,
            responsive: {
                0: {
                    items: 1,
                    loop: false,
                    dots: true,
                },
                814: {
                    items: 2,
                    loop: false,
                    dots: true,
                },
                1230: {
                    items: 3,
                    loop: false,
                    dots: true,
                }
            }
        });

    })
})(jQuery, window);
	(function ($, w) {
    $(function () {
        let $container = $('#competencies');
        if (!$container.length) return;
        let data = JSON.parse($container.find('#competenciesData').html());
        let $linksModal = $container.find('.js_show-modal');
        let $modal = $container.find('#competenciesModal');

        $linksModal.on('click', function (e) {
            e.preventDefault();
            let $this = $(this);
            let key = $this.attr('data-key');
            $('.modal.in').modal('hide');
            setTimeout(function () {
                $modal.find('.js_modal-content').html(data[key]['content']);
                $modal.find('.js_modal-title').html(data[key]['title']);
                $modal.modal('show');
            }, 300);
        });

    })
})(jQuery, window);
	(function ($, w) {
    $(function () {
        let $form = $('.js_form-callback');
        let sending = false;
        $form.validate({
            errorClass: "is-invalid",
            focusInvalid: false,
            errorElement: "div",
            submitHandler: function (form) {
                let $form = $(form);
                if (!sending) {
                    sending = true;
                    new Noty({
                        text: "Отправка формы",
                        type: "warning",
                        timeout: 3000
                    }).show();
                    $.post('/send/', $form.serializeArray(), function (e) {
                        sending = false;
                        if (e.success !== undefined) {
                            new Noty({
                                text: 'Заявка успешно отправлена',
                                type: "success",
                                timeout: 3000,
                            }).show();
                            $form.trigger('reset');
                        } else if (e.error !== undefined) {
                            new Noty({
                                text: 'Ошибка отправки',
                                timeout: 3000,
                                type: "error",
                            }).show();
                        }
                    });
                }
            },
            errorPlacement: function ($error, $element) {
                let $form = $element.parents('form');
                let $errorContainer = $form.find('#'+$element.attr('aria-describedby'));
                if ($errorContainer.length) {
                    $errorContainer.html($error.text());
                } else {
                    $element.append($error);
                }
            }
        })
    })
})(jQuery);
	(function ($, w) {
    $(function () {
        var $container = $('.js_course-lesson');
        let $modal = $('#courseLessonModal');
        var $slider = $container.find('.js_lessen-slider');

        $container.each(function (e) {
            var $this = $(this);

            var data = JSON.parse($this.find('#courseLessonData').html());
            var $linksModal = $this.find('.js_show-modal');

            $linksModal.on('click', function (e) {
                e.preventDefault();
                let $this = $(this);
                let key = $this.attr('data-key');
                $('.modal.in').modal('hide');
                setTimeout(function () {
                    $modal.find('.js_modal-content').html(data[key]['content']);
                    $modal.find('.js_modal-title').html(data[key]['title']);
                    $modal.modal('show');
                }, 300);
            });
        })










        $slider.owlCarousel({
            stagePadding: 0,
            lazyLoad: true,
            margin: 30,
            items: 4,
            loop: false,
            dots: false,
            responsive: {
                0 : {
                    items: 1,
                    loop: false,
                    dots: true,
                    autoWidth:true,
                },
                900: {
                    items: 2,
                    loop: false,
                    dots: true,
                    autoWidth:true,
                },
                1230: {
                    items: 3,
                    loop: false,
                    dots: true,
                    autoWidth:true,
                },
                1500: {
                    items: 4,
                    loop: false,
                    dots: true,
                    autoWidth:true,
                }
            }
        });



    })
})(jQuery, window);

	(function ($, w) {
    $(function () {
        let $links = $('.js_play-youtube');

        $links.each(function () {
            let $this = $(this);
            let start = $this.attr('data-start');
            // console.log(start);
            $this.fancybox({
                helpers: {
                    media: true
                },
                youtube: {
                    autoplay: 1, // enable autoplay
                    start: start // set start time in seconds (embed)
                }
            });
        })


    })
})(jQuery, window);

	(function ($, w) {
    $(function () {
        let $sliderElement = $('.js-video-slider');


        $sliderElement.owlCarousel({
            stagePadding: 28,
            lazyLoad: true,
            margin: 30,
            items: 4,
            loop: false,
            dots: false,
            autoHeight: true,
            responsive: {
                0 : {
                    items: 1,
                    loop: false,
                    dots: true,
                    autoHeight: true,
                },
                814: {
                    items: 2,
                    loop: false,
                    dots: true,
                    autoHeight: true,
                },
                1230: {
                    items: 3,
                    loop: false,
                    dots: true,
                    autoHeight: true,
                },
                1500: {
                    items: 4,
                    loop: false,
                    dots: true,
                    autoHeight: true,
                }
            }
        });
    });
})(jQuery, window);

	(function ($, w) {
    $(function () {
        let $sliderElement = $('.js_about-slider');


        $sliderElement.owlCarousel({
            stagePadding: 28,
            lazyLoad: true,
            margin: 30,
            items: 4,
            loop: false,
            dots: false,
            autoHeight: true,
            responsive: {
                0 : {
                    items: 1,
                    loop: false,
                    dots: true,
                    autoHeight: true,
                    margin: 0,
                },
                600 : {
                    items: 2,
                    loop: false,
                    dots: true,
                    autoHeight: true,
                },
                814: {
                    items: 3,
                    loop: false,
                    dots: true,
                    autoHeight: true,
                },
                1230: {
                    items: 4,
                    loop: false,
                    dots: true,
                    autoHeight: true,
                },
                1500: {
                    items: 5,
                    loop: false,
                    dots: true,
                    autoHeight: true,
                }
            }
        });
    });
})(jQuery, window);

}
